import "./../styles/global.css";
import h from "../styles/home.module.css"
import { Navbar } from "../components/Navbar"
import { HeaderImage, RImage } from "../components/Images"
import { Footer } from "../components/Footer"

export default function Home() {
    return(
        <>
            <Navbar/>
            <div className={ h.header }>
                <HeaderImage route="home/hero" alt="Mujer en la playa"/>
            </div>

            {/* Next adventure */}
            <div className={ h.adventure }>
                <img className={ h.adventure_bg2 } src="./images/home/adventure_bg2.png" srcSet="./images/home/adventure_bg2.webp" alt="Background"/>
                <img className={ h.adventure_bg } src="./images/home/adventure.png" srcSet="./images/home/adventure.webp" alt="Background"/>
                <div className={ h.adventure_images }>
                    <RImage route="home/adventure1" alt="Caminando en la playa"/>
                    <RImage route="home/adventure2" alt="Mujer surfeando"/>
                    <RImage route="home/adventure3" alt="Mujer con tortuga"/>
                    <div className={ h.adventure_images_des }>SKY ABOVE, SAND<br/>BELOW. DISCONNECT<br/>AND ENJOY.</div>
                </div>
            </div>

            {/* RIDE THE WAVE */}
            <div className={ h.ride }>
                <HeaderImage route="home/ride" alt="Mujer surfeando"/>
            </div>

            {/* PROFIT */}
            <div className={ h.profit_content }>
                <div className={ h.profit }>
                    <div>10% OF OUR PROFITS GOES TO SAVE THE OCEANS</div>
                    <div>10% OF OUR PROFITS GOES TO SAVE THE OCEANS</div>
                    <div>10% OF OUR PROFITS GOES TO SAVE THE OCEANS</div>
                    <div>10% OF OUR PROFITS GOES TO SAVE THE OCEANS</div>
                    <div>10% OF OUR PROFITS GOES TO SAVE THE OCEANS</div>
                    <div>10% OF OUR PROFITS GOES TO SAVE THE OCEANS</div>
                    <div>10% OF OUR PROFITS GOES TO SAVE THE OCEANS</div>
                    <div>10% OF OUR PROFITS GOES TO SAVE THE OCEANS</div>
                    <div>10% OF OUR PROFITS GOES TO SAVE THE OCEANS</div>
                    <div>10% OF OUR PROFITS GOES TO SAVE THE OCEANS</div>
                </div>
            </div>

            {/* FOTOS */}
            <div className={ h.fotos }>
                <RImage route="home/fotos_bg" alt="Palmera"/>
                <div className={ h.fotos_content }>
                    <div className={ h.fotos_con }>
                        <RImage route="home/find_adventure" alt="Hombre pescando"/>
                    </div>
                    <div className={ h.fotos_con }>
                        <RImage route="home/dare_del_mar" alt="Mujer sentada en palmera"/>
                    </div>
                    <div className={ h.fotos_con_above }>
                        <RImage route="home/our_story" alt="Mujer acostada en la playa"/>
                    </div>
                    <div className={ h.fotos_des }>
                        <div className={ h.fotos_des_t1 }>know our story</div>
                        <div className={ h.fotos_des_t2 }>For those good moments where the sun, the beach and friends come together to create adventures and memories.</div>
                        <a href="https://shop.somosdelmar.mx/" className={ h.fotos_des_btn }>SHOP NOW</a>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}