import "./../styles/global.css";
import p from "../styles/products.module.css"
import { NavbarBlack } from "../components/Navbar"
import { HeaderImage, RImage } from "../components/Images"
import { Footer } from "../components/Footer"
import { Cerveza, Merchandise } from "../components/Productos";

export default function Store() {
    return(
        <>
            <NavbarBlack/>
            <div className={ p.header }>
                <HeaderImage route="productos/hero" alt="Mujer en la playa"/>
            </div>

            {/* CERVEZAS */}
            <div className={ p.cervezas }>
                <div className={ p.cervezas_content }>
                    <div className={ p.cervezas_images }>
                        <div className={ p.cervezas_images_el }>
                            <div className={ p.cervezas_images_el_title }>WEST COAST IPA</div>
                            <RImage route="productos/beer1" alt="Cerveza"/>
                        </div>
                        <div className={ p.cervezas_images_el }>
                            <div className={ p.cervezas_images_el_title }>TANNED ALE</div>
                            <RImage route="productos/beer2" alt="Cerveza"/>
                        </div>
                        <div className={ p.cervezas_images_el }>
                            <div className={ p.cervezas_images_el_title }>CALIFORNIAN LAGER</div>
                            <RImage route="productos/beer3" alt="Cerveza"/>
                        </div>
                    </div>
                    <div className={ p.cervezas_t1 }> COLLECT MOMENTS, ADVENTURES<br/>AND GOOD FRIENDS</div>
                    <div className={ p.cervezas_t2 }>SOMOS DEL MAR</div>
                </div>
            </div>

            {/* OUR STORY */}
            <div className={ p.story }>
                <RImage route="productos/story_bg" alt="Fondo"/>
                <div className={ p.story_des }>
                    <div className={ p.story_des_content }>
                        <div className={ p.story_des_t1 }>know our story</div>
                        <div className={ p.story_des_t2 }>For those good moments where the sun, the beach and friends come together to create adventures and memories</div>
                        <div className={ p.story_des_sep }></div>
                        <div className={ p.story_des_t3 }>SOMOS DEL MAR</div>
                    </div>
                    <RImage route="productos/cervezas" alt="Cervezas"/>
                </div>
                <div className={ p.story_fotos }>
                    <RImage route="productos/story1"/>
                    <RImage route="productos/story2"/>
                    <RImage route="productos/story3"/>
                </div>
            </div>

            {/* SHOP */}
            <div className={ p.shop }>
                <div className={ p.shop_t1 }>SHOP</div>
                <div className={ p.shop_t2 }>Crafting cool moments is our life, beer<br/>is our oasis, somos del mar.</div>
                <div className={ p.shop_content }>
                    <Cerveza img="productos/buzz" nombre="Buzz Bait" precio="80" link="https://shop.somosdelmar.mx/products/buzz-bait-californian-ipa"/>
                    <Cerveza img="productos/sandy" nombre="Sandy Vibes" precio="80" link="https://shop.somosdelmar.mx/products/sandy-feet-californian-lager"/>
                    <Cerveza img="productos/sunset" nombre="Sunset" precio="80" link="https://shop.somosdelmar.mx/products/sunset-tanned-red-ale"/>
                </div>
            </div>

            {/* MERCHANDISE */}
            <div className={ p.merchandise }>
                <div className={ p.merchandise_t1 }>MERCHANDISE</div>
                <div className={ p.merchandise_t2 }>Gear up for a journey in the Baja Vibe’s. Our<br/>merch is not just an accessory; it's a<br/>statement of your cool soul.</div>
                <div className={ p.merchandise_content }>
                    <Merchandise link="tote-bag" img="productos/tote_bag" subtitle="APPAREL" title="TOTE BAG DEL MAR" price="380" des="Elevate your Baja style with our hipster-approved tote bags. Carry the coastal vibes wherever you go – because cool isn't just a look, it's a lifestyle."/>
                    <Merchandise link="bucket-hat" img="productos/bucket_hat" subtitle="APPAREL" title="BUCKET HAT DEL MAR" price="380" des="Dive into laid-back Baja vibes with our trendy bucket hats. Embrace the sun-soaked coolness while making a style statement that's as bold as the peninsula itself."/>
                    <Merchandise link="t-shirt" img="productos/playera" subtitle="APPAREL" title="T SHIRT DEL MAR" price="380" des="Wrap yourself in Baja's cool essence with our hipster-chic t-shirts. Effortlessly stylish, these tees are more than fabric – they're a statement of the sun-soaked, laid-back lifestyle we live and love."/>
                </div>
            </div>

            {/* FOLLOW US */}
            <div className={ p.follow }>
                <RImage route="productos/follow1" alt="Cervezas en la playa"/>
                <RImage route="productos/follow2" alt="Cervezas en la playa"/>
                <div className={ p.follow_des }>
                    <div className={ p.follow_des_t1 }>SOMOS DEL MAR</div>
                    <div className={ p.follow_des_t2 }>FOLLOW US</div>
                    <div className={ p.follow_des_social }>
                        <a href="https://www.facebook.com/delmar.brew" target="_blank"></a>
                        <a href="https://www.instagram.com/delmar.brew" target="_blank"></a>
                        <RImage route="productos/social" alt="Social media"/>
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    )
}