import { Link } from "react-router-dom"
import { RImage } from "./Images"
import { useEffect, useState } from "react"

export function Navbar() {
    const [ navbar_scroll_classes, setnavbar_scroll_classes ] = useState('');

    useEffect(() => {
        setnavbar_scroll_classes('navbar');
        window.addEventListener('scroll', () => {
            if ( window.scrollY < 200 ) {
                setnavbar_scroll_classes('navbar');
            } else {
                setnavbar_scroll_classes('navbar fixed');
            }
        });
    }, []);

    return(
        <div className={ navbar_scroll_classes }>
            <div className="navbar_content">
                <Link to="/" className="navbar_logo">
                    <RImage route="logo_white" alt="Logo"/>
                </Link>
                <div className="navbar_options">
                    <Link to="/" className="navbar_element">HOME</Link>
                    <Link to="/store" className="navbar_element">PRODUCTS</Link>
                    <a href="https://shop.somosdelmar.mx/" className="navbar_element">SHOP NOW</a>
                </div>
            </div>
        </div>
    )
}

export function NavbarBlack() {
    const [ navbar_scroll_classes, setnavbar_scroll_classes ] = useState('');

    useEffect(() => {
        setnavbar_scroll_classes('navbar black');
        window.addEventListener('scroll', () => {
            if ( window.scrollY < 200 ) {
                setnavbar_scroll_classes('navbar black');
            } else {
                setnavbar_scroll_classes('navbar black fixed');
            }
        });
    }, []);

    return(
        <div className={ navbar_scroll_classes }>
            <div className="navbar_content">
                <Link to="/" className="navbar_logo">
                    <RImage route="logo_black" alt="Logo"/>
                </Link>
                <div className="navbar_options black">
                    <Link to="/" className="navbar_element">HOME</Link>
                    <Link to="/store" className="navbar_element">PRODUCTS</Link>
                    <a href="https://shop.somosdelmar.mx/" className="navbar_element">SHOP NOW</a>
                </div>
            </div>
        </div>
    )
}