export function HeaderImage({ route, alt }) {
    return(
        <picture>
            <source type="image/webp" srcSet={ "/images/" + route + "@1920.webp" } media="(min-width:1400px)"/>
            <source type="image/png" srcSet={ "/images/" + route + "@1920.png" } media="(min-width:1400px)"/>
            <source type="image/webp" srcSet={ "/images/" + route +"@1500.webp" } media="(max-width:1399px) and (min-width: 1200px)"/>
            <source type="image/png" srcSet={ "/images/" + route +"@1500.png" } media="(max-width:1399px) and (min-width: 1200px)"/>
            <source type="image/webp" srcSet={ "/images/" + route +"@1200.webp" } media="(max-width:1199px) and (min-width: 900px)"/>
            <source type="image/png" srcSet={ "/images/" + route +"@1200.png" } media="(max-width:1199px) and (min-width: 900px)"/>
            <source type="image/webp" srcSet={ "/images/" + route +"@900.webp" } media="(max-width:899px) and (min-width: 600px)"/>
            <source type="image/png" srcSet={ "/images/" + route +"@900.png" } media="(max-width:899px) and (min-width: 600px)"/>
            <img src={ "/images/" + route +"@600.png" } srcSet={ "/images/" + route + "@600.webp" } alt={ alt }/>
        </picture>
    )
}

export function RImage({ route, alt, classes="" }) {
    return(
        <img className={ classes } src={ "/images/" + route + ".png" } srcSet={ "/images/" + route + ".webp" } alt={ alt }/>
    )
}