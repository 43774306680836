import React from "react"
import ReactDOM from "react-dom/client"

import ScrollToTop from "./components/ScrollToTop"

import { BrowserRouter, Routes, Route } from "react-router-dom"

/* Páginas */
import Home from "./pages/home"
import Store from "./pages/store"
/* Final páginas */

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <BrowserRouter>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={ <Home/> }/>
                <Route path="/store" element={ <Store/> }/>
                {/* Error 404 */}
                <Route path="*" element={ <div>Página no encontrada</div> }/>
            </Routes>
        </BrowserRouter>
    </>
);